
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
/**
 * @module 404 Error
 * @description 404 Error Page
 */
import Head from 'next/head';
import Link from 'next/link';
import Template from '@/templates/Default';

/**
 * @function Page
 * @description Page
 */
const Page = () => (
  <>
    <Head>
      <title>404 Error | Internet</title>
      <meta name="description" content="Call 844-322-9391 to find out if Frontier Internet & Phone services are available near you. Landline packages offer unlimited local calling, voicemail & more." />
    </Head>

    <Template
      title="Error"
      primary={
        <>
          <div className="l-container">
            <div className="site-error">
              <h2>404 Error</h2>
              <Link href="/" className="btn btn--blue">
                <span className="icon icon-arrow-left2"></span>Back to home
              </Link>
            </div>
          </div>
        </>
      }>
    </Template>
  </>
);

export default Page;
